// pages/404.js
import { Card } from 'components';
import { ReactElement } from 'react';

export default function Custom404(): ReactElement {
  return (
    <>
      <div className="flex items-center justify-center h-screen">
        <Card className="text-center p-10">
          {/* <h1 className="text-lg">The page you are looking for is not available.</h1> */}

          <div className="bg-blue-100 rounded-lg py-5 px-6 mb-4 text-base text-blue-700 mb-3 " role="alert">
            <h1 className="text-2xl">The page you are looking for is not available.</h1>
            <div className="flex items-center justify-center">
              <p className="mt-3 text-lg w-1/2">
                If you have any questions or concerns, please reach out to us at{' '}
                <a
                  href="mailto:help@systemandsoul.com"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold text-blue-600 dark:text-blue-500 hover:underline"
                >
                  help@systemandsoul.com
                </a>
              </p>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
